import { createRouter, createWebHistory } from 'vue-router'
import { Auth } from 'aws-amplify'
import vueAppFunctionsVariables from '@/components/components-varfuns/vueAppFunctionsVariables.vue'
import ClientLayout from "@/layouts/ClientLayout.vue"
import UserLayout from "@/layouts/UserLayout.vue"
import { getUserStore } from '@/store/index'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('@/pages/HomePage.vue'),
        meta: { layout: ClientLayout }
    },
    {
        path: '/About',
        name: 'About',
        component: () => import('@/pages/AboutPage.vue'),
        meta: { layout: ClientLayout }
    },
    {
        path: '/Recipe/:recipeId',
        name: 'RecipeURL',
        component: () => import('@/views/RecipeURLView.vue'),
        meta: { layout: ClientLayout }
    },
    {
        path: '/Auth',
        name: 'Auth',
        component: () => import('@/components/core/AmplifyAuth.vue'),
        meta: { layout: ClientLayout }
    },
    {
        path: '/Profile',
        name: 'Profile',
        component: () => import('@/pages/ProfilePage.vue'),
        meta: { requiresAuth: true, layout: UserLayout }
    },
    {
        path: '/Recipes',
        name: 'Recipes',
        component: () => import('@/pages/RecipesPage.vue'),
        meta: { requiresAuth: true, layout: UserLayout }
    },
    {
        path: '/YourRecipes',
        name: 'Your Recipes',
        component: () => import('@/pages/YourRecipesPage.vue'),
        meta: { requiresAuth: true, layout: UserLayout }
    },
    {
        path: '/Chat',
        name: 'Chat',
        component: () => import('@/pages/ChatPage.vue'),
        meta: { requiresAuth: true, layout: UserLayout }
    },
    {
        path: '/SmartSearch',
        name: 'Smart Search',
        component: () => import('@/pages/SmartSearchPage.vue'),
        meta: { requiresAuth: true, layout: UserLayout }
    },
    {
        path: '/ShoppingList',
        name: 'Shopping List',
        component: () => import('@/pages/ShoppingListPage.vue'),
        meta: { requiresAuth: true, layout: UserLayout }
    },
    {
        path: '/Settings',
        name: 'Settings',
        component: () => import('@/pages/SettingsPage.vue'),
        meta: { requiresAuth: true, layout: UserLayout }
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'Page Not Found',
        component: () => import('@/pages/PageNotFound.vue'),
        meta: { layout: ClientLayout }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    async scrollBehavior() {
        await new Promise(resolve => setTimeout(resolve, 500))
        return { left: 0, top: 0 }
    },
});

router.beforeEach(async (to, from, next) => {
    const store = getUserStore();
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const currentAuthenticatedUser = await Auth.currentAuthenticatedUser().catch(() => { });
    const userData = await vueAppFunctionsVariables.getCognitoUserData();
    if (userData && userData.UserAttributes) {
        store.storeUser(userData.UserAttributes)
        store.storeCurrentAuthenticatedUser(currentAuthenticatedUser ? currentAuthenticatedUser : undefined)
    }

    if (!requiresAuth || (requiresAuth && userData)) {
        next();
    } else {
        next({
            path: '/Auth',
            query: { redirect: to.fullPath }
        });
    }
});

export default router