<template>
  <div id="user-layout">
    <VAppBar ref="appBar" app flat rounded="lg" color="primary" fixed class="rounded-t-0">
      <VAppBarNavIcon class="white--text" @click="drawer = !drawer" />
      <VChip class="pa-0">
        <VBtn color="white" size="x-small" icon @click="goBack">
          <VIcon size="x-large">mdi-arrow-left</VIcon>
        </VBtn>
        <VBtn color="white" size="x-small" icon @click="goForward">
          <VIcon size="x-large">mdi-arrow-right</VIcon>
        </VBtn>
      </VChip>
      <VToolbarTitle class="white--text ml-3">{{ currentRouteName }}</VToolbarTitle>
      <VSpacer />
      <VImg :src="require('@/assets/img-other/logo-white.svg')" max-height="30" max-width="30" style="margin:0 17px;" />
    </VAppBar>

    <VNavigationDrawer absolute v-model="drawer" app class="pa-3">
      <template v-slot:prepend>
        <VListItem id="nav-drawer-user" :title="userData.name !== '' ? userData.name : 'Login'"
          :subtitle="userData.username !== '' ? userData.username : 'Or Sign Up Here!'" class="py-2 px-0"
          @click="$router.push({ path: '/Profile' })" rounded="lg">
          <template v-slot:prepend>
            <VAvatar size="50" class="mr-3">
              <img :src="userData.userImg ? userData.userImg : require('@/assets/img-other/user-profile-img-blank.png')"
                alt="avatar" height="50" width="50" cover>
            </VAvatar>
          </template>

          <template v-slot:append>
            <VBtn size="small" variant="text" icon :active="currentRouteName === 'Profile'" to="/Profile">
              <VIcon size="x-large" :color="currentRouteName === 'Profile' ? 'primary' : ''">{{ currentRouteName ===
                'Profile' ? 'mdi-menu-right' : 'mdi-menu-down' }}</VIcon>
            </VBtn>
          </template>
        </VListItem>
      </template>

      <v-divider class="mt-3"></v-divider>

      <VList :lines="false" nav app class="app-list">
        <v-list-item v-for="(item, i) in sidebarItems" :active="item.title === currentRouteName" :key="i"
          :value="item.value" :to="item.to" color="primary"
          @click="executeFunc(typeof item.click === 'string' ? item.click : '')" rounded="lg">
          <template v-slot:prepend>
            <v-icon :icon="item.icon"></v-icon>
          </template>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </VList>

      <div class="text-center" style="position: absolute;
           bottom: 12px;
           width: calc(100% - 24px);">
        <div>
          <VChip variant="flat" class="bg-primary">{{ appVersion }}</VChip>
        </div>
      </div>
    </VNavigationDrawer>

    <v-main>
      <router-view />
    </v-main>

    <VBottomNavigation v-if="currentRouteName !== 'Auth'" id="botton-navigation" app flat rounded="lg" fixed grow
      class="rounded-b-0" bg-color="primary">
      <VBtn v-for="(btn, id) in bottomNavBtns" :key="id">
        <VIcon>{{ btn.icon }}</VIcon>
        {{ btn.text }}
      </VBtn>
    </VBottomNavigation>
    <LoadingDialog v-model="loadingDialog" />
  </div>
</template>

<script lang="ts" setup>
import route from '@/router'
import {
  ref, watchEffect,
  onBeforeUnmount,
  computed
} from 'vue'
import {
  Auth,
  DataStore
} from 'aws-amplify'
import { UserData, UserRecipeData } from '@/models'
import { useRouter } from 'vue-router'
import { getUserStore, getDeviceStore } from '@/store/index'
import LoadingDialog from '@/components/components-global/LoadingDialog.vue'
import vueAppFunctionsVariables from '@/components/components-varfuns/vueAppFunctionsVariables.vue'
import { clearAllData } from '@/components/components-varfuns/vueDynamoDBFunctons'
// import _ from 'lodash'

const appVersion = vueAppFunctionsVariables.appVersion
const storeUser = getUserStore()
const storeDevice = getDeviceStore()
const isOnline = computed(() => storeDevice.isOnline)
const isLoading = computed(() => storeDevice.isLoading)
const userData = computed(() => storeUser.userData)
const bottomNavItemsArray = vueAppFunctionsVariables.bottomNavItemsArray
const sidebarItems = [
  {
    title: 'Home',
    value: 1,
    to: '/',
    icon: 'mdi-home',
  },
  {
    title: 'Recipes',
    value: 2,
    to: '/Recipes',
    icon: 'mdi-book',
  },
  // {
  //     title: 'Shopping List',
  //     value: 3,
  //     to: '/shoppinglist',
  //     icon: 'mdi-shopping',
  // },
  // {
  //     title: 'Chat',
  //     value: 4,
  //     to: '/chat',
  //     icon: 'mdi-forum',
  // },
  {
    title: 'Profile',
    value: 5,
    to: '/Profile',
    icon: 'mdi-account',
  },
  {
    title: 'Settings',
    value: 6,
    to: '/Settings',
    icon: 'mdi-cog',
  },
  {
    title: 'About',
    value: 7,
    to: '/About',
    icon: 'mdi-information',
  },
  {
    title: userData.value.currentAuthenticatedUser ? 'Sign Out' : 'Sign In',
    value: 8,
    // to: '',
    click: userData.value.currentAuthenticatedUser ? 'signOut' : 'signIn',
    icon: userData.value.currentAuthenticatedUser ? 'mdi-logout' : 'mdi-login',
    // disabled: !userData.value.currentAuthenticatedUser,
  },
]
const bottomNavBtns = ref()

const loadingDialog = ref(false)
const drawer = ref(false)
const currentRouteName = ref('')
const router = useRouter()
const importRoute = ref(route)
const observeQueryRecipeSubscription = ref()
const observeQueryUserSubscription = ref()
const observeQueryUserRecipeDataSubscription = ref()


// onMounted(async () => {
//   try {
//   } catch (error) {
//     console.log('An error occurred while configuring DataStore:', error);
//   }
// })

watchEffect(() => {
  if (!userData.value.currentAuthenticatedUser) {
    if (observeQueryUserSubscription.value) {
      observeQueryUserSubscription.value.unsubscribe()
    }
    if (observeQueryUserRecipeDataSubscription.value) {
      observeQueryUserRecipeDataSubscription.value.unsubscribe()
    }
  } else {
    if (!observeQueryUserSubscription.value) {
      observeQueryUserSubscription.value = DataStore.observeQuery(
        UserData
      ).subscribe(async snapshot => {
        const { items, isSynced } = snapshot;
        if (isSynced && isOnline.value && items.length > 0 && items[0].userImgURL) {
          await storeUser.storeUserImg(items[0].userImgURL, items[0].updatedAt);
        } else if (!isOnline.value) {
          await storeUser.storeUserImgOffline();
        }
      });
    }
    if (!observeQueryUserRecipeDataSubscription.value) {
      observeQueryUserRecipeDataSubscription.value = DataStore.observeQuery(
        UserRecipeData
      ).subscribe(async snapshot => {
        const { items, isSynced } = snapshot;
        if (isSynced && items.length > 0) {
          storeUser.storeUserRecipeData(items[0]);
        } else if (isSynced && items.length === 0 && isOnline.value && userData.value.currentAuthenticatedUser) {

          await DataStore.save(
            new UserRecipeData({
              favoritedRecipes: [],
              sharedRecipes: [],
              viewedRecipes: [],
            })
          )
        }
      });
    }
  }

  if (isLoading.value && !loadingDialog.value) {
    loadingDialog.value = true
  }
  else if (!isLoading.value && loadingDialog.value) {
    loadingDialog.value = false
  }

  if (typeof importRoute.value.currentRoute.name === 'string') {
    if (importRoute.value.currentRoute.name && (importRoute.value.currentRoute.name !== currentRouteName.value)) {

      currentRouteName.value = importRoute.value.currentRoute.name
      changeBottomNavBtns(importRoute.value.currentRoute.name)
    }
  }
})


function goForward() {
  if (importRoute.value.options.history.state.forward) {
    importRoute.value.forward(); // Go back in the browser history
  }
}

function goBack() {
  if (importRoute.value.options.history.state.back) {
    importRoute.value.back(); // Go back in the browser history
    // importRoute.value.go(-1); // Go back in the browser history
  } else {
    importRoute.value.push({ path: '/' }); // Navigate to the home page if no history to go back
  }
}

function executeFunc(functionName: string) {
  try {
    if (functionName === 'signOut') {
      signOut()
    } else if (functionName === 'signIn') {
      signIn()
    }
    // else console.log('functionName:', functionName)
  } catch (error) {
    console.log(error)
  }
}

function changeBottomNavBtns(newRouteName: string) {
  if (typeof newRouteName === 'undefined') {
    bottomNavBtns.value = []
  } else if (newRouteName === 'Home') {
    bottomNavBtns.value = bottomNavItemsArray.home
  } else if (newRouteName === 'Recipes') {
    bottomNavBtns.value = bottomNavItemsArray.recipes
  } else bottomNavBtns.value = []
}

async function signOut() {
  try {
    loadingDialog.value = true
    await Auth.signOut()
    await DataStore.clear()
    storeUser.clearUser()
    await clearAllData()
    loadingDialog.value = false
    router.push({ path: '/' }).then(() => {
      if (window) window.location.reload()
    })
  } catch (error) {
    loadingDialog.value = false
    console.log('error signing out: ', error)
  }
}

async function signIn() {
  try {
    router.push({ path: '/Auth' })
  } catch (error) {
    console.log('error signing out: ', error)
  }
}

onBeforeUnmount(() => {
  if (observeQueryRecipeSubscription.value) {
    observeQueryRecipeSubscription.value.unsubscribe()
  }
  if (observeQueryUserSubscription.value) {
    observeQueryUserSubscription.value.unsubscribe()
  }
  if (observeQueryUserRecipeDataSubscription.value) {
    observeQueryUserRecipeDataSubscription.value.unsubscribe()
  }
})
</script>
<style>
.app-list {
  padding: 12px 0 !important;
}

#user-layout {
  display: flex;
  flex-grow: 1;
}

#loadingOverlay>span.v-btn__loader>div {
  height: 50px !important;
  width: 50px !important;
}
</style>