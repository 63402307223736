import { defineStore } from 'pinia'
import { ref, reactive } from 'vue'
import { Storage } from 'aws-amplify'
import vueAppFunctionsVariables from '@/components/components-varfuns/vueAppFunctionsVariables.vue'
import { storeBase64String, getBase64StringByIdOrEmail, getUpdatedAtImgStore, deleteBase64StringsByRecipeIdOrEmail } from '@/components/components-varfuns/vueDynamoDBFunctons'

export const getUserStore = defineStore('user', {
  state: () => ({
    stateUserData: reactive({
      userImgKey: ref(''),
      userImg: ref(''),
      username: ref(''),
      name: ref(''),
      email: ref(''),
      cognitoUserName: ref(''),
      currentAuthenticatedUser: ref()
    }),
    stateUserRecipeData: ref({
      favoritedRecipes: ref(['']),
      sharedRecipes: ref(['']),
      viewedRecipes: ref(['']),
    }),
  }),
  getters: {
    userData: state => state.stateUserData,
    // userRecipeData: state => state.stateUserRecipeData,
  },
  actions: {
    storeUserRecipeData(userRecipeData) {
      this.stateUserRecipeData = userRecipeData;
    },

    clearUser() {
      try {
        this.stateUserData.userImgKey = ref('')
        this.stateUserData.userImg = ref('')
        this.stateUserData.username = ref('')
        this.stateUserData.name = ref('')
        this.stateUserData.email = ref('')
        this.stateUserData.cognitoUserName = ref('')
        this.stateUserData.currentAuthenticatedUser = ref()
      } catch (error) {
        console.log(error)
      }
    },
    async storeUserImg(userImgUrl, userUpdatedAt) {
      try {
        if (this.stateUserData.userImgKey !== userImgUrl) {
          const updatedAtImgStore = await getUpdatedAtImgStore('user', this.stateUserData.email);
          if (updatedAtImgStore) {
            if (this.stateUserData.userImg === '' && (userUpdatedAt === updatedAtImgStore)) {
              const base64String = await getBase64StringByIdOrEmail('user', this.stateUserData.email);
              const image = base64String ? JSON.parse(base64String) : undefined;
              const dCrypt = image ? await vueAppFunctionsVariables.decryptString(image) : '';
              this.stateUserData.userImg = dCrypt;
            } else if (userUpdatedAt !== updatedAtImgStore) {
              const imageDownload = await Storage.get(userImgUrl, {
                level: 'private',
                download: true,
              })
              if (imageDownload) {
                await deleteBase64StringsByRecipeIdOrEmail('user', this.stateUserData.email)
                const r = await vueAppFunctionsVariables.convertImageToString(imageDownload)
                const eCrypt = await vueAppFunctionsVariables.encryptString(r)
                const didStoreUserImg = await storeBase64String('user', this.stateUserData.email, JSON.stringify(eCrypt), userUpdatedAt)
                this.stateUserData.userImg = r;
                if (!didStoreUserImg) {
                  console.log("Storage Full")
                }
              }
            }
          } else {
            this.stateUserData.userImgKey = userImgUrl

            const imageDownload = await Storage.get(userImgUrl, {
              level: 'private',
              download: true,
            })
            let imageString;
            let didStoreUserImg;
            if (imageDownload) {
              imageString = await vueAppFunctionsVariables.convertImageToString(imageDownload)
              const eCrypt = await vueAppFunctionsVariables.encryptString(imageString)
              didStoreUserImg = await storeBase64String('user', this.stateUserData.email, JSON.stringify(eCrypt), userUpdatedAt)
            }
            if (!didStoreUserImg) {
              console.log(didStoreUserImg)
              console.log("Storage Full")
            }
            this.stateUserData.userImg = imageString
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    async storeUserImgOffline() {
      try {
        const base64String = await getBase64StringByIdOrEmail('user', this.stateUserData.email);

        if (base64String) {
          let imageString;
          try {
            imageString = await JSON.parse(base64String);
          } catch (jsonError) {
            console.error('Error parsing base64String as JSON', jsonError);
          }
          let dCrypt;
          try {
            dCrypt = imageString ? await vueAppFunctionsVariables.decryptString(imageString) : ''
          } catch (decryptError) {
            console.error('Error decrypting the image', decryptError);
            throw decryptError;
          }
          this.stateUserData.userImg = dCrypt;
        } else {
          console.log('No UserImgStored retrieved');
        }
      } catch (error) {
        console.error('Error in storeUserImgOffline', error);
      }
    },
    storeUser(userData) {
      userData.forEach((item) => {
        if (item.Name === 'email') {
          this.stateUserData.email = item.Value;
        } else if (item.Name === 'preferred_username') {
          this.stateUserData.username = item.Value;
        } else if (item.Name === 'sub') {
          this.stateUserData.cognitoUserName = item.Value;
        } else if (item.Name === 'name') {
          this.stateUserData.name = item.Value;
        }
      });
    },
    storeCurrentAuthenticatedUser(userData) {
      this.stateUserData.currentAuthenticatedUser = userData
    },
  },
})
export const getDeviceStore = defineStore('deviceStore', {
  state: () => ({
    stateIsOnline: ref(false),
    stateIsLoading: ref(false)
  }),
  getters: {
    isOnline: state => state.stateIsOnline,
    isLoading: state => state.stateIsLoading
  },
  actions: {
    storeIsOnline(boolValue) {
      this.stateIsOnline = boolValue
    },
    storeIsLoading(boolValue) {
      this.stateIsLoading = boolValue
    },
  },
})
export const getRecipeImagesStore = defineStore('recipesImagesStore', {
  state: () => ({
    stateStoredRecipeImages: reactive([{
      id: ref(''),
      imageUrl: ref(''),
    }])
  }),
  getters: {
    storedRecipeImages: state => state.stateStoredRecipeImages
  },
  actions: {
    storeRecipeImg(recipeImgObj) {
      try {
        this.stateStoredRecipeImages.push(recipeImgObj)
      } catch (error) {
        console.log(error)
      }
    },
  }
}
)