<template>
    <div id="network-status-comp"></div>
</template>
<script>
import { onMounted, onBeforeUnmount, watchEffect, computed } from 'vue';
import { getDeviceStore } from '@/store/index'

export default {
    name: 'NetworkStatus',

    setup() {
        const storeDevice = getDeviceStore();
        const isOnline = computed(() => storeDevice.isOnline)

        // Watch for changes in isOnline and isOffline
        watchEffect(() => {
            if (navigator.onLine && !isOnline.value) {
                storeDevice.storeIsOnline(true); // Update this based on how you have defined storeDevice
            } else if (!navigator.onLine && isOnline.value) {
                storeDevice.storeIsOnline(false); // Update this based on how you have defined storeDevice
            }
        });

        onMounted(() => {
            try {
                if (window) {
                    // Initial state
                    storeDevice.storeIsOnline(navigator.onLine); // Update the initial status

                    // Attach event listeners
                    window.addEventListener('online', onlineHandler);
                    window.addEventListener('offline', offlineHandler);
                }
            } catch (error) {
                console.error('An error occurred:', error);
            }
        });

        onBeforeUnmount(() => {
            if (window) {
                window.removeEventListener('online', onlineHandler);
                window.removeEventListener('offline', offlineHandler);
            }
        });

        function onlineHandler() {
            if (!isOnline.value) {
                storeDevice.storeIsOnline(true);
            }
        }

        function offlineHandler() {
            if (isOnline.value) {
                storeDevice.storeIsOnline(false);
            }
        }
    },
};
</script>