import { createApp } from 'vue'
import App from '@/App.vue'
import vuetify from '@/plugins/vuetify.js'
import { loadFonts } from './plugins/webfontloader'
import { createPinia } from 'pinia'
import './registerServiceWorker'
import { Amplify, AuthModeStrategyType, DataStore } from 'aws-amplify'
import awsExports from '@/aws-exports.js'
import router from '@/router/index.js'
import AmplifyVue from '@aws-amplify/ui-vue'

await DataStore.stop()
Amplify.configure({
  ...awsExports,
  // Auth: {
  //   aws_appsync_authenticationType: "AWS_IAM",
  //   authModeStrategyType: AuthModeStrategyType.MULTI_AUTH,
  // },
  DataStore: {
    authModeStrategyType: AuthModeStrategyType.MULTI_AUTH,
    region: 'eu-west-2',
  },
  Storage: {
    AWSS3: {
      bucket: 'veganfl-storage-49e62297123339-master', //REQUIRED -  Amazon S3 bucket name
      region: 'eu-west-2', //OPTIONAL -  Amazon service region
    }
  }
})
// await DataStore.start()

loadFonts()

const app = createApp(App)
  .use(router)
  .use(createPinia())
  .use(vuetify)
  .use(AmplifyVue)
export const appInstance = app.mount('#app');