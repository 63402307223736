<script>
import { cloneDeep } from 'lodash'
export default {
    name: 'vueAppFunctionsVariables',
    appVersion: 'V 6.4 Beta',
    bottomNavItemsArray: {
        home: [{
            id: 0,
            text: 'Home',
            value: 1,
            to: '/',
            icon: 'mdi-home'
        },
        ],
        recipes: [
            //     {
            //     id: 0,
            //     text: 'All Recipes',
            //     to: '/',
            //     icon: 'mdi-home'
            // }, 
            {
                id: 0,
                text: 'Recipes',
                to: '/Recipes',
                icon: 'mdi-book'
            },
        ],
    },
    sidebarItems: [
        {
            title: 'Home',
            value: 1,
            to: '/',
            icon: 'mdi-home',
        },
        {
            title: 'Recipes',
            value: 2,
            to: '/Recipes',
            icon: 'mdi-book',
        },
        // {
        //     title: 'Shopping List',
        //     value: 3,
        //     to: '/shoppinglist',
        //     icon: 'mdi-shopping',
        // },
        // {
        //     title: 'Chat',
        //     value: 4,
        //     to: '/chat',
        //     icon: 'mdi-forum',
        // },
        {
            title: 'Profile',
            value: 5,
            to: '/Profile',
            icon: 'mdi-account',
        },
        {
            title: 'Settings',
            value: 6,
            to: '/Settings',
            icon: 'mdi-cog',
        },
        {
            title: 'About',
            value: 7,
            to: '/About',
            icon: 'mdi-information',
        },
        {
            title: 'Sign Out',
            value: 8,
            // to: '',
            click: 'signOut',
            icon: 'mdi-logout',
        },
    ],
    encryptURLString(str) {
        const encryptedCharacters = [];

        for (let i = 0; i < str.length; i++) {
            const char = str.charCodeAt(i);

            // Apply transformations for stronger encryption
            const encryptedChar = char + 123; // Example transformation
            encryptedCharacters.push(String.fromCharCode(encryptedChar));

            // Place a random character after each encrypted character
            const randomChar = String.fromCharCode(Math.floor(Math.random() * 94) + 32);
            encryptedCharacters.push(randomChar);
        }

        return encodeURIComponent(encryptedCharacters.join('')); // URL-friendly encoding
    },

    decryptURLString(encryptedStr) {
        const decodedEncryptedStr = decodeURIComponent(encryptedStr);
        const encryptedCharacters = decodedEncryptedStr.split('');
        const decryptedCharacters = [];

        for (let i = 0; i < encryptedCharacters.length; i += 2) {
            const encryptedChar = encryptedCharacters[i].charCodeAt(0);

            // Reverse transformations for decryption
            const decryptedChar = encryptedChar - 123; // Example transformation
            decryptedCharacters.push(String.fromCharCode(decryptedChar));
        }

        return decryptedCharacters.join('');
    },

    encryptString(str) {
        const characters = str.split('');
        const encryptedCharacters = [];

        for (let i = 0; i < characters.length; i++) {
            const char = characters[i];
            encryptedCharacters.push(char);

            // Place a random character after each character
            const randomChar = String.fromCharCode(Math.floor(Math.random() * 94) + 32);
            encryptedCharacters.push(randomChar);
        }

        return encryptedCharacters.join('');
    },
    decryptString(encryptedStr) {
        const encryptedCharacters = encryptedStr.split('');
        const decryptedCharacters = [];

        for (let i = 0; i < encryptedCharacters.length; i += 2) {
            const char = encryptedCharacters[i];
            decryptedCharacters.push(char);
        }

        return decryptedCharacters.join('');
    },
    async convertImageToString(image) {
        try {
            const imageLocal = image ? cloneDeep(image) : undefined
            if (imageLocal) {
                const reader = new FileReader();

                const promiseLocal = new Promise((resolve) => {
                    reader.onload = () => {
                        const imageString = reader.result;
                        resolve(imageString);
                    };

                    reader.onerror = () => {
                        resolve(undefined);
                    };

                    reader.readAsDataURL(imageLocal.Body);
                });
                return promiseLocal
            } else return undefined

        } catch (error) {
            console.log(error);
            return undefined;
        }
    },
    getCognitoUserData() {
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            if (key.startsWith('CognitoIdentityServiceProvider.') && key.endsWith('.userData')) {
                const value = JSON.parse(localStorage.getItem(key));
                return value ? value : undefined
            }
        }
        return undefined; // Key not found
    }
}
</script>