<template>
  <VApp id="app" v-if="appLoaded">
    <component :is="route.meta.layout">
      <router-view />
    </component>
    <ServiceWorkerUpdate />
    <NetworkStatus />
  </VApp>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import NetworkStatus from '@/components/components-varfuns/NetworkStatus.vue'
import ServiceWorkerUpdate from '@/components/core/ServiceWorkerUpdate.vue'

// --------------------------------------------------------------------------------------

const route = useRoute()
const appLoaded = ref(false)

onMounted(() => {
  try {
    appLoaded.value = true
  } catch (error) {
    console.error('An error occurred:', error);
    appLoaded.value = true
  }
})

// --------------------------------------------------------------------------------------

</script>

<style>
#app {
  font-family: 'Ubuntu', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #388E3C;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>